import { Box, Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        display: 'flex',
        flex: 1,
        margin: theme.spacing(1)
    },
}));

function SkillCard(props) {
    const classes = useStyles();

    return (
        <a href={props.link} style={{ textDecoration: 'none', flexGrow: 1 }}  target="_blank">
            <Paper elevation={12} className="tool-item" style={{transition: 'transform 250ms ease-in-out'}}>
                <Box minHeight={90}>
                    <div className="tool-item-icon">
                        {props.icon}
                    </div>
                    <div className="tool-item-title">
                        <h2>
                            {props.text}
                        </h2>
                    </div>
                    <div class="tool-item-description">
                        {props.description ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque eleifend donec pretium vulputate. Nibh venenatis cras sed felis eget. Ut diam quam nulla porttitor massa id neque aliquam. Hac habitasse platea dictumst quisque sagittis. Nec ultrices dui sapien eget mi proin sed libero. Diam phasellus vestibulum lorem sed risus ultricies tristique."}
                    </div>
                </Box>
            </Paper>
        </a>
    );
}

export default SkillCard;