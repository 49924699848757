import React, { useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, createMuiTheme, ThemeProvider, Typography } from '@material-ui/core';
import StorageIcon from '@mui/icons-material/Storage';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CloudIcon from '@mui/icons-material/Cloud';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CodeIcon from '@mui/icons-material/Code';
import BugReportIcon from '@mui/icons-material/BugReport';

import clsx from 'clsx';

import Background from './res/bg.jpg';
import Particles from 'react-particles';
import { loadFull } from "tsparticles";

import "@fontsource/poppins";
import ToolCard from './components/ToolCard';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    headerImage: {
        height: '100%',
        backgroundImage: `url(${Background})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
    },
    content: {
        display: 'flex',
        zIndex: '2',
        overflowY: 'auto',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    toolContainer: {
        display: 'flex',
        
        flexWrap: 'wrap',
        flex: 1,
        fontSize: 12,
        marginLeft: '10%',
        marginRight: '10%',
    },
    titleContainer: {
        zIndex: '1',
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
        // marginLeft: '10%',
        // marginRight: '10%',
    },
    tools: {
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    titleText: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'row',
        textAlign: 'center'
    },
    subTitleText: {
        
        margin: theme.spacing(2),
        textAlign: 'center'
    },
    appBar: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    '@global': {
        html: {
            WebkitFontSmoothing: 'auto',
        },
    },
}));

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        fontFamily: 'Poppins'
    }
});

function App() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const classes = useStyles();

    const renderParticles = () => {
        return (
            <Particles
            init={particlesInit}
            style={{position: 'absolute', backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',}}
            options={{
                retina_detect: true,
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 1,
                        }
                    },
                },
                particles: {
                    color: {
                        value: "#2196f3",
                    },
                    links: {
                        color: "#2196f3",
                        distance: 100,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        direction: "random",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: true,
                        speed: 3,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 1000,
                        },
                        value: 170,
                    },
                    opacity: {
                        value: 0.5,
                        anim: {
                            enable: true,
                            speed: 5,
                            opacity_min: 0.5
                        }
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 3 },
                    },
                },
                detectRetina: true,
            }}
            />
        );
    }

    const renderContent = () => {
        return ( 
            <div className={classes.content}>
                {renderTools()}
            </div>
        );
    }

    const renderTools = () => {
        // if (navigator.userAgent.match(/Android/i)
        //         || navigator.userAgent.match(/webOS/i)
        //         || navigator.userAgent.match(/iPhone/i) 
        //         || navigator.userAgent.match(/iPad/i) 
        //         || navigator.userAgent.match(/iPod/i)
        //         || navigator.userAgent.match(/BlackBerry/i)
        //         || navigator.userAgent.match(/Windows Phone/i)) {
        //     return (<html>Mobile is WIP</html>);
        // } else {

            return (
                <div className={classes.titleContainer}>
                    <div className={classes.subTitleText}>
                        <h1 variant='h2' className="content-title">
                            Developer Links
                        </h1>
                    </div>
                    <div className="content-title-underline"></div>
                    <div className={classes.toolContainer}>
                        <div className={classes.tools}>
                            <ToolCard icon={<StorageIcon />} text="Tools" link="https://randomdev.atlassian.net/wiki/spaces/RANDOM/pages/33069/Developer+Tools" description="An overview of the development tools and services that will be used for projects." />
                            <ToolCard icon={<ChangeCircleIcon />} text="Jenkins" link="https://jenkins.nickreimherr.dev/" description="The jenkins instance that will be used to run CI/CD pipelines for development projects."/>
                            <ToolCard icon={<CodeIcon />} text="Gitlab" link="https://gitlab.com/randdev" description="A link to the team Gitlab instance where all project repositories are hosted."/>
                        </div>
                        <div className={classes.tools}>
                            <ToolCard icon={<BugReportIcon />} text="Jira" link="https://randomdev.atlassian.net/jira/software/c/projects/RAN/boards/1" description="A link to the JIRA instance used for issue tracking and metrics to track progress."/>
                            <ToolCard icon={<StorefrontIcon />} text="Confluence" link="https://randomdev.atlassian.net/wiki/spaces/RANDOM/overview" description="The location for all project documentation. Contains the team development set up guide."/>
                            <ToolCard icon={<CloudIcon />} text="AWS" link="https://aws.amazon.com/" description="A link to the aws console. Setup instructions located on the team confluence."/>
                        </div>
                    </div>
                </div>
            );
            
    //    }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={clsx(classes.root, classes.headerImage)}>
                {renderParticles()}
                {renderContent()}
            </div>
        </ThemeProvider>
    );
}

export default App;
